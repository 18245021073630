import React, { lazy, Suspense } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import { Provider } from "react-redux";
import CookieConsent from "react-cookie-consent";

// Store
import store from "./redux/store";

// Routes
import Routes from "./routes";

// Components
const Navigation = lazy(() => import("./components/navigation"));
const Footer = lazy(() => import("./components/footer"));

// Styles
import "./App.css";
import classNames from "classnames/bind";
import styles from "./style.module.css";
const cx = classNames.bind(styles);

const App = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense>
            <Navigation />
            <div className={cx("content-wrapper")}>
              <Routes />
            </div>
            <Footer />
          </Suspense>
          <CookieConsent
            style={{ background: "#e8ca54", color: "black", fontSize: 20, fontWeight: 500 }}
            buttonStyle={{ background: "unset", color: "#007bff" }}
            buttonText="OK"
          >
            We use cookies to ensure you have the best browsing experience on our website. Please read our <Link to={"/cookies"}>cookie policy</Link>{" "}
            for more information about how we use cookies.
          </CookieConsent>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default App;
